import styled from '@emotion/styled';
import { Button } from '@mui/material';

const CustomLoginButton = styled(Button)(() => ({
  padding: '9px 43px 10px 43px !important',
  borderRadius: '10px !important',
  fontSize: '16px !important',
  fontWeight: '500 !important',
  textTransform: 'capitalize',
  //height: '60px !important',
}));

export default CustomLoginButton;
