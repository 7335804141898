import { Avatar, Box, Drawer, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
// import NotificationModal from '../Modals/NotificationModal';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ProfileModal from '../modal/ProfileModal';
// import ProfileModal from '../Modals/ProfileModal';
import profilePicStatic from './../assets/images/profile.png';

const UserInfo = ({ adminData }: any) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [state, setState] = React.useState({
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  return (
    <Box display='flex' alignItems='center'>
      <Box className=''>
        <Stack spacing={0}>
          <Avatar sizes='sm' className='notification-icon'>
            <NotificationsNoneIcon
              sx={{ cursor: 'pointer' }}
              onClick={toggleDrawer('right', true)}
            />
            <Drawer anchor={'right'} open={state.right} onClose={toggleDrawer('right', false)}>
              {/* <NotificationModal /> */}
            </Drawer>
          </Avatar>
        </Stack>
      </Box>
      <Box
        className='avatar-box'
        onClick={() => {
          handleOpen();
        }}
      >
        <Box width={'67px'} className='cursor-pointer'>
          <Avatar sizes={'40px'} alt='' src={adminData?.data?.user?.profilePic || profilePicStatic}>
            {adminData?.data?.user?.name?.[0]?.toUpperCase()}
          </Avatar>
        </Box>
        <Box width={'132px'}>
          <Typography color='#265AA8' fontWeight={500} fontSize='16px' lineHeight='21px'>
            {adminData?.data?.user?.name}
          </Typography>
          <Typography color='#777676' fontSize='14px' lineHeight='21px'>
            {adminData?.data?.user?.userRole?.name}
          </Typography>
        </Box>
      </Box>
      {open && <ProfileModal open={open} handleOpen={handleOpen} handleClose={handleClose} />}
    </Box>
  );
};

export default UserInfo;
