const ColumnIcon = () => {
  return (
    <svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <mask id='path-1-inside-1_2092_27396' fill='white'>
        <rect x='1.83325' y='2.66797' width='17.6' height='14.8' rx='1' />
      </mask>
      <rect
        x='1.83325'
        y='2.66797'
        width='17.6'
        height='14.8'
        rx='1'
        stroke='#265AA8'
        strokeWidth='2.8'
        mask='url(#path-1-inside-1_2092_27396)'
      />
      <line
        x1='8.13359'
        y1='3.06641'
        x2='8.13359'
        y2='17.0664'
        stroke='#265AA8'
        strokeWidth='1.4'
      />
      <line
        x1='13.7332'
        y1='3.06641'
        x2='13.7332'
        y2='17.0664'
        stroke='#265AA8'
        strokeWidth='1.4'
      />
      <line
        x1='19.4336'
        y1='6.16875'
        x2='1.83359'
        y2='6.16875'
        stroke='#265AA8'
        strokeWidth='1.4'
      />
    </svg>
  );
};

export default ColumnIcon;
