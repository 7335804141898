import { Box, Divider, Pagination } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import ButtonComponent from './ButtonComponent';
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { useLocation, useNavigate } from 'react-router-dom';
import FilterIcon from '../assets/svg/FilterIcon';
import ColumnIcon from '../assets/svg/ColumnIcon';
interface btnData {
  title: string;
  isBtnShow?: boolean;
  linkData: Array<{
    menuItem: string;
    to: string;
  }>;
}

const PaginationWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  position: 'absolute',
  width: '96%',
  bottom: '14px',
});

interface tableInterface {
  rows: any[];
  columns: any[];
  detailsRedirection?: string;
}

const columns = [
  { id: 'no', label: 'Sr.No', borderBottom: 'none' },
  { id: 'name', label: 'Name', fontcolor: '#fff' },
  {
    id: 'ide',
    label: 'ID',
  },
  {
    id: 'department',
    label: 'Department',
  },
  {
    id: 'line',
    label: 'Line',
  },
  {
    id: 'currentStatus',
    label: 'Current Status',
    display: 'flex',
    alignItems: 'center',
  },
  {
    id: 'nextAvailableOn',
    label: 'Next available on',
  },
  {
    id: 'action',
    label: 'Action',
  },
];

const TableComponent = (props: {
  data: btnData;
  tableData?: tableInterface;
  isOptionShow: boolean;
  isEditStock?: boolean;
  width?: number;
  isLoading?: boolean;
  paginationData: any;
  page: number;
  setPage: any;
}) => {
  const width = props?.width ? props.width : 100;
  let isOptionShow = true;
  if (isOptionShow || isOptionShow === false) {
    isOptionShow = props.isOptionShow;
  }
  const navigate = useNavigate();
  const columnsData = props?.tableData != null ? props.tableData.columns : columns;
  const [rowsData, setRowsData] = useState<any>(
    props?.tableData != null ? props.tableData.rows : []
  );
  const CustomDataGrid = styled(DataGrid)(() => ({
    '.MuiDataGrid-cell': {
      borderBottom: 'none',
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    [`& .${gridClasses.row}.odd`]: {
      backgroundColor: '#F4F9FF',
    },
  }));

  let isBtnShow = true;

  if (props?.data?.isBtnShow === true || props.data?.isBtnShow === false) {
    isBtnShow = props?.data?.isBtnShow;
  }

  useEffect(() => {
    setRowsData(props?.tableData?.rows);
  }, [props?.isLoading, props.tableData?.rows?.length]);

  const { data, paginationData, page, setPage } = props;
  console.log('paginationData', paginationData);
  const CustomDivider = styled(Divider)(() => ({
    margin: '0px 16px 0px 18px !important',
  }));
  const location = useLocation();
  function CustomToolbar() {
    const isHideOptions =
      location.pathname.startsWith('/edit-product') || location.pathname.startsWith('/add-product');
    return (
      <>
        {props.isOptionShow && !isHideOptions && (
          <Box className='table-toolbar'>
            <GridToolbarContainer>
              <GridToolbarColumnsButton name='Customize Columns' />
              <CustomDivider orientation='vertical' />
              <GridToolbarFilterButton />
              {isBtnShow && (
                <>
                  <CustomDivider orientation='vertical' />
                  <ButtonComponent title={data.title} linkData={data.linkData} />
                </>
              )}
            </GridToolbarContainer>
          </Box>
        )}
      </>
    );
  }

  return (
    <React.Fragment>
      <Box className='table-height' width={`${width}%`}>
        <CustomDataGrid
          rows={rowsData || []}
          // getRowHeight={() => 40}
          getRowHeight={() => 'auto'}
          hideFooter
          // pagination
          paginationMode='server'
          // pageSize={10}
          loading={props.isLoading}
          // headerHeight={33}
          columns={columnsData}
          // pageSizeOptions={[5, 10, 12]}
          // autoPageSize
          // size
          // paginationModel={{ page: 0, pageSize: 8 }}
          // onPaginationModelChange={(data) => {
          //   console.log('data===>', data);
          //   // updateData("page", data.page + 1);
          // }}
          onCellClick={(e: any) => {
            if (e.field !== 'actions') {
              const isRedeirction = props.tableData?.detailsRedirection;
              if (isRedeirction && props.tableData?.detailsRedirection !== 'noRedirection') {
                navigate(`${props.tableData?.detailsRedirection}/${e?.id}`);
              }
            }
          }}
          getRowClassName={(params: any) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          components={{
            Toolbar: CustomToolbar,
            OpenFilterButtonIcon: FilterIcon,
            ColumnSelectorIcon: ColumnIcon,
          }}
          componentsProps={{
            panel: {
              sx: {
                top: '-30px !important',
                left: '810px !important',
              },
            },
          }}
        />
        <PaginationWrapper>
          <Pagination
            page={page}
            color='primary'
            count={paginationData?.totalPages}
            variant='outlined'
            onChange={(e, page) => {
              setPage(page);
            }}
          />
        </PaginationWrapper>
      </Box>
    </React.Fragment>
  );
};
export default TableComponent;
