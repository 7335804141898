import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { type MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';

const CustomDivider = styled(Divider)(() => ({
  borderColor: '#F6F6F6 !important',
  margin: '11px 0px 12px 0px !important',
}));

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 10,
    border: '1px solid #D2D1D1',
    marginTop: theme.spacing(1),
    minWidth: 180,
    boxSizing: 'border-box',
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    '& .MuiMenu-list': {
      padding: '10px',
    },
    '& .MuiMenuItem-root': {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&.Mui-selected': {
        backgroundColor: '#e44444',
      },
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export default function ButtonComponent(props: {
  title: string;
  linkData: Array<{ menuItem: string; to: string }>;
}) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Button
        id='demo-customized-button'
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        variant='contained'
        disableElevation
        onClick={handleClick}
        sx={{
          width: 'auto',
          marginLeft: '28px',
          background: '#F19E38',
          borderRadius: '10px',
          height: '40px',
        }}
      >
        {props.title}
      </Button>
      <StyledMenu
        id='demo-customized-menu'
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {props.linkData.map((link, ind: number) => {
          return (
            <Box
              key={ind}
              onClick={() => {
                navigate(link.to);
              }}
            >
              <MenuItem
                onClick={handleClose}
                disableRipple
                sx={{
                  width: '290px',
                  color: '#265AA8',
                  fontSize: '14px',
                  lineHeight: '28px',
                  fontWeight: 500,
                  padding: '0px',
                  marginLeft: '11px',
                }}
              >
                {link.menuItem}
              </MenuItem>
              {ind !== props.linkData.length - 1 && <CustomDivider />}
            </Box>
          );
        })}
      </StyledMenu>
    </div>
  );
}
