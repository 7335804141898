import React, { type FC } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ComponentWrapper from './genralComponents/ComponentWrapper';
import SupperAdmin from './supperAdmin/SupperAdmin';
import AddNewAdmin from './supperAdmin/AddNewAdmin';
import AdminDetails from './supperAdmin/AdminDetails';
import Login from './supperAdmin/pages/Login';

const App: FC = () => {
  return (
    <Router>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/' element={<ComponentWrapper />}>
          <Route path='/' element={<SupperAdmin />} />
          <Route path='/add-admin' element={<AddNewAdmin />} />
          <Route path='/edit-admin/:id' element={<AddNewAdmin />} />
          <Route path='/admin-details/:id' element={<AdminDetails />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
