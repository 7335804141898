import { get, post, patch, deleteRequest } from '../Utils/index';

export const addAdmin = async (data) => {
  return post(`/admin/user`, data);
};
export const getSingleAdmin = async (data) => {
  const id = data?.queryKey[1];
  return get(`/admin/user/${id}`);
};
export const getAdmins = async (data) => {
  const page = data?.queryKey[1] || 1;
  const payload = { page, limit: 10 };
  return get(`/admin/user`, payload);
};
export const updateAdmin = async (data) => {
  console.log('data--', data);
  const id = data?.id;
  delete data['id'];
  return patch(`/admin/user/${id}`, data);
};

export const deleteAdmin = async (id) => {
  return deleteRequest(`/admin/user/${id}`);
};
export const adminApi = {
  addAdmin,
  getSingleAdmin,
  getAdmins,
  updateAdmin,
  deleteAdmin,
};
