import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
// import { useUpdateProfile } from '../../hooks/authHooks';
// import { useUploadFile } from '../../hooks/uploadHook';
// import { user } from '../../queryKeys/userManagement';
import { Avatar } from '@mui/material';
import { user } from '../queryKeys/userManagement';
import { useUploadFile } from '../hooks/uploadHook';
import { useUpdateProfile } from '../hooks/authHooks';

const ProfileAvatar = ({ handleOpen }: any) => {
  const queryClient = useQueryClient();
  const userData: any = queryClient.getQueryData([user.LOGGED_IN_USER]);
  const adminProfile: any = userData?.data?.user;
  const { mutate: uploadFile, data, isSuccess: isProfilePicUploadSuccess } = useUploadFile();
  const profilePic = data?.data?.data[0]?.docLink;
  const { mutate: updateProfile } = useUpdateProfile();

  React.useEffect(() => {
    if (isProfilePicUploadSuccess) {
      updateProfile({ id: adminProfile?.id, profilePic: profilePic });
      handleOpen();
    }
  }, [isProfilePicUploadSuccess]);

  const handleFile = (e) => {
    const fileData = e.target.files[0];
    const formData = new FormData();
    formData.append('file', fileData);
    formData.append('fileName', fileData.name);
    const img = new Image();
    img.src = window.URL.createObjectURL(e.target.files[0]);
    img.onload = () => {
      return uploadFile(formData);
    };
  };

  return (
    <div>
      <label htmlFor='newFile'>
        <Avatar
          className='cursor-pointer'
          sx={{ width: 50, height: 50 }}
          alt=''
          src={profilePic || adminProfile?.profilePic}
        >
          {adminProfile?.displayName?.[0]?.toUpperCase() || adminProfile?.name?.[0]?.toUpperCase()}
        </Avatar>
        <input onChange={(e) => handleFile(e)} type='file' id='newFile' hidden />
      </label>
    </div>
  );
};

export default ProfileAvatar;
