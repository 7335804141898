import { patch, post, upload } from '../Utils/index';
export const addFile = async (data) => {
  return upload(`/admin/upload/files`, data);
};
// export const updateFile = async (data) => {
//   const id = data?.id;
//   delete data['id'];
//   return patch(`/upload/files/${id}`, data);
// };

export const uploadApi = {
  addFile,
};
