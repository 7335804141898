import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Form, Formik } from 'formik';
import { TextField, Typography } from '@mui/material';
import * as Yup from 'yup';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useLogin } from '../../hooks/authHooks';
import InovacLogo from '../../assets/svg/InovacLogo';
import HighMarkError from '../../assets/svg/HighMarkError';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import { cookie } from '../../Utils/Cookies/cookie';
import { useNavigate } from 'react-router-dom';

const CustomLoginButton = styled(Button)(() => ({
  padding: '9px 43px 10px 43px !important',
  borderRadius: '10px !important',
  fontSize: '16px !important',
  fontWeight: '500 !important',
  textTransform: 'capitalize',
  //height: '60px !important',
}));

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('email is required'),
  password: Yup.string().required('password is Required'),
});

const Input = styled(TextField)(() => ({
  borderRadius: '10px',
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '48px',
    padding: '1px 14px',
  },

  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill': {
    borderRadius: '10px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#D2D1D1',
      borderRadius: '10px',
    },
  },
}));

const HeadBox = styled(Box)(() => ({
  background: 'linear-gradient(180deg, #265AA8 0%, #AACCFF 104.19%)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  overflow: 'auto',
  '.login-box': {
    //height: '450px',
    width: '503px',
    justifyContent: 'center',
  },
  '.head-image': {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '101px',
  },
  '.validation-text': {
    color: 'red',
    fontSize: '12px',
  },
  '.field-title': {
    fontSize: 14,
    fontWeight: 500,
    padding: '0px 0px 6px 0px',
    color: '#fff',
    //marginTop: '15px',
  },
  '.input-field': {
    background: '#FFFFFF',
    width: '400px',
    borderRadius: '10px',
  },
  '.login-btn-box': {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '40px',
  },
  //   '.error-box': {

  //   },
  '.left-box': {
    width: '10px',
    height: '84px',
    background: '#CC0000',
  },
  '.right-box': {
    width: '493px',
    height: '52px',
    background: '#F5CCCC',
  },
  '.close-icon': {
    cursor: 'pointer',
  },
  '.login-btn': {
    padding: '9px 20px 10px !important',
    borderRadius: '10px !important',
    fontSize: '16px !important',
    fontWeight: 500,
    textTransform: 'capitalize',
  },
}));

const Login = () => {
  const navigate = useNavigate();
  const token = cookie.get('token');
  if (token) {
    navigate('/');
  }
  const [errorBox, setErrorBox] = React.useState<boolean>(false);
  const handleClose = () => setErrorBox(false);
  const handleOpen = () => setErrorBox(true);
  const { mutate: addLogin } = useLogin(handleOpen);

  const icon = <AiOutlineInfoCircle color='red' />;

  return (
    <React.Fragment>
      <HeadBox>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={LoginSchema}
          onSubmit={(values) => {
            addLogin(values);
          }}
        >
          {({ errors, touched, handleSubmit, handleChange, isValid, dirty }) => (
            <Form onSubmit={handleSubmit}>
              <Box className='login-box'>
                <Box className='head-image'>
                  {/* <img src={logo} alt='logo' height={123} width={336} /> */}
                  <InovacLogo />
                </Box>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Box>
                      <Typography className='field-title' mt={'15px'}>
                        ID
                        {/* <Require /> */}
                      </Typography>

                      <Input
                        placeholder='Enter Id'
                        fullWidth
                        className='input-field'
                        onChange={handleChange}
                        name='email'
                        InputProps={{
                          endAdornment: touched.email && errors.email && icon,
                        }}
                      />
                      {touched.email && errors.email && (
                        <Typography className='validation-text'>{errors.email}</Typography>
                      )}
                    </Box>
                    <Box>
                      <Typography className='field-title' mt='15px'>
                        Password
                        {/* <Require /> */}
                      </Typography>
                      <Input
                        placeholder='Enter Password'
                        fullWidth
                        type='password'
                        className='input-field'
                        onChange={handleChange}
                        name='password'
                        InputProps={{
                          endAdornment: touched.password && errors.password && icon,
                        }}
                      />
                      {touched.password && errors.password && (
                        <Typography className='validation-text'>{errors.password}</Typography>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Box className='login-btn-box'>
                  {errorBox ? (
                    <>
                      <Box display={'flex'}>
                        <Box className='left-box'></Box>
                        <Box className='right-box' p={2}>
                          <Box display={'flex'} justifyContent={'space-between'}>
                            <Box>
                              <Box display={'flex'}>
                                <HighMarkError />
                                <Typography className='field-tit' ml={2}>
                                  You have entered incorrect password
                                </Typography>
                              </Box>
                              <Box>
                                <Typography className='field-title' ml={6}>
                                  Please try again or contact the admin
                                </Typography>
                              </Box>
                            </Box>
                            <Box onClick={() => handleClose()} className='close-icon'>
                              <CloseIcon />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </>
                  ) : (
                    <>
                      <CustomLoginButton
                        type='submit'
                        className={!(isValid && dirty) ? 'disable-btn' : 'yellow-bg'}
                      >
                        Login
                      </CustomLoginButton>
                    </>
                  )}
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </HeadBox>
    </React.Fragment>
  );
};
export default Login;
