/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { MenuItem, FormControl } from '@material-ui/core';
// import Loading from 'react-fullscreen-loading';
import {
  Box,
  Divider,
  Grid,
  TextField,
  Typography,
  Select,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Checkbox,
  Button,
  TableCell,
} from '@mui/material';
import { Formik, Form } from 'formik';
import React, { type FC } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import BreadcrumbNavigation from '../commomComponent/BreadCrumbNavigation';
import Require from '../commomComponent/Required';
import CheckboxIcon from '../assets/svg/CheckboxIcon';
import CheckboxFilledIcon from '../assets/svg/CheckboxFilledIcon';
import CustomButton from '../commomComponent/CustomButton';
import { type PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useAddAdmin, useGetAdmin, useUpdateAdmin } from '../hooks/adminHooks';
import moment from 'moment';
import ErrorPopup from '../commomComponent/ErrorPopup';
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('name is required')
    .matches(/^([A-Za-z\s]*)$/gi, 'Only letters allowed'),
  uniqId: Yup.string().required('uniqId is required'),
  factoryName: Yup.string().required('factory name is required'),
  numberOfWorker: Yup.number()
    .typeError('only number allowed')
    .required('number of Worker is required'),
  numberOfWebendUser: Yup.number()
    .typeError('only number allowed')
    .required('number of web end user is required'),
  typeOfAccount: Yup.string().required('type of account is required'),
  email: Yup.string().email('Email must be a valid email').required('email is required'),
  mobile: Yup.string()
    .matches(/^\d{10}$/, 'Please enter a valid 10-digit phone number.')
    .required('Phone number is required.'),
  rights: Yup.array(),
  startDate: Yup.string().required('Select date is Required'),
});

const Input = styled(TextField)(() => ({
  borderRadius: '8px',
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '48px',
    padding: '1px 14px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#D2D1D1',
      borderRadius: '10px',
    },
  },
}));

const Selects = styled(Select)(() => ({
  borderRadius: '8px !important',
  '.MuiOutlinedInput-input': {
    padding: '13.5px 14px',
  },
}));

const BoxWrapper = styled(Box)(() => ({
  marginTop: '27px',
  paddingBottom: '20px',
  '.validation-text': {
    color: 'red',
    fontSize: '12px',
  },
  '.button-box': {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '20px',
  },
  '.field-title': {
    fontSize: 14,
    fontWeight: 500,
    padding: '0px 0px 6px 0px',
    color: '#000',
  },
  '.disable-btn': {
    background: '#C9C9C9',
  },
}));
const AddNewAdmin: FC = () => {
  const [isEdit, setIsEdit] = React.useState(false);
  const [isSubscriptionBasic, setIsSubscriptionBasic] = React.useState(false);
  const icon = <AiOutlineInfoCircle color='red' />;
  // const [modalValues, setModalValues] = React.useState({});
  // const [isModalOpen, setIsModalOpen] = React.useState(false);
  //add Admin
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const handleErrorModalClose = () => setIsErrorModalOpen(false);
  const { mutate: addAdmin, isError: isAddAdminError, error: addAdminError } = useAddAdmin();
  const { id } = useParams();

  //get single Admin
  const { data: getAdmin } = useGetAdmin(id, !!id);
  const admin: {
    name: string;
    uniqId: string;
    factoryName: string;
    typeOfAccount: string;
    email: string;
    mobile: string;
    numberOfWorker: number;
    numberOfWebendUser: number;
    startDate: string;
    rights: any;
    subscriptions: any;
  } = getAdmin?.user;

  //update admin
  const {
    mutate: updateAdmin,
    isError: isUpdateAdminError,
    error: updateAdminError,
  } = useUpdateAdmin();

  // const handleOpen = (): void => {
  //   setIsModalOpen(true);
  // };
  // const handleClose = (): void => {
  //   setIsModalOpen(false);
  // };

  React.useEffect(() => {
    if (id) {
      setIsEdit(true);
    }
  }, [id]);
  React.useEffect(() => {
    if (isAddAdminError || isUpdateAdminError) {
      isAddAdminError
        ? setErrorMessage(addAdminError?.data?.message)
        : setErrorMessage(updateAdminError?.data?.message);
      setIsErrorModalOpen(true);
      // handleClose();
    }
  }, [isAddAdminError, isUpdateAdminError]);

  const TableDataCell = styled(TableCell)(() => ({
    // padding: '16px 0px 16px 16px',
    padding: '4px',
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    '.MuiTableCell-root': {
      borderBottom: 'none !important',
    },
    '&:nth-of-type(odd)': {
      backgroundColor: '#F4F9FF',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const modulesToAccessAdvance = [
    'dashboard',
    'factoryManagement',
    'resources',
    'catalogue',
    'processes',
    'workorders',
    'eventBasedTriggersAndNotifications',
    'issuesReporting',
    'ticket',
  ];
  const rightsArr = modulesToAccessAdvance.map((module) => {
    return {
      userModule: module,
      access: false,
    };
  });

  const fetchedSubscription = modulesToAccessAdvance?.map((access) => {
    const currentModule = admin?.subscriptions?.find(
      (permission) => permission?.userModule === access
    );
    return currentModule;
  });
  const modulesToAccessBasic = [
    'dashboard',
    'factoryManagement',
    'resources',
    'catalogue',
    'processes',
    'workorders',
  ];
  const rightsArrBasic = modulesToAccessBasic.map((module) => {
    return {
      userModule: module,
      access: false,
    };
  });

  const fetchedSubscriptionBasic = modulesToAccessBasic?.map((access) => {
    const currentModule = admin?.subscriptions?.find(
      (permission) => permission?.userModule === access
    );
    return currentModule;
  });

  // TODO:- CUSTOM DATE PICKER
  const ActionList = (props: PickersActionBarProps) => {
    //remove onCancel in props
    const { onAccept, onClear, onSetToday } = props;
    const actions = [
      { text: 'Apply', method: onAccept },
      { text: 'Clear', method: onClear },
      // { text: 'Cancel', method: onCancel },
      { text: 'Today', method: onSetToday },
    ];
    return (
      <Box className='datepicker-btn-wrapper'>
        <Button className='datepicker-btn' variant='outlined' onClick={() => onAccept()}>
          {actions[0].text}
        </Button>
        <Button className='yellow-bg datepicker-btn' onClick={() => onClear()}>
          {actions[1].text}
        </Button>
      </Box>
    );
  };
  return (
    <BoxWrapper>
      <BreadcrumbNavigation pageTitle={`${isEdit ? 'Edit' : 'Add new'} Admin`} backToLink='/' />
      <Formik
        initialValues={{
          name: isEdit ? admin?.name : '',
          uniqId: isEdit ? admin?.uniqId : '',
          factoryName: isEdit ? admin?.factoryName : '',
          typeOfAccount: isEdit ? admin?.typeOfAccount : '',
          email: isEdit ? admin?.email : '',
          mobile: isEdit ? admin?.mobile : '',
          numberOfWorker: isEdit ? admin?.numberOfWorker : '',
          numberOfWebendUser: isEdit ? admin?.numberOfWebendUser : '',
          startDate: isEdit ? admin?.startDate : '',
          rights: isEdit ? fetchedSubscription : rightsArr,
          // rights: isEdit
          //   ? admin?.typeOfAccount === 'Basic'
          //     ? fetchedSubscriptionBasic
          //     : // : fetchedSubscription
          //     admin?.typeOfAccount === 'Advance'
          //     ? fetchedSubscription
          //     : []
          //   : [],
          // // : values?.typeOfAccount === 'Basic'
          // // ? rightsArrBasic
          // // : rightsArr,
        }}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          //   setModalValues(values);
          //   handleOpen();
          const updateValues = {
            ...values,
            // password: 'Password@123',
            startDate: values?.startDate ? values?.startDate : moment().format('YYYY-MM-DD'),
          };
          id ? updateAdmin({ ...updateValues, id }) : addAdmin(updateValues);
        }}
      >
        {({ values, touched, errors, handleChange, isValid, dirty, setFieldValue }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography className='field-title'>
                  name
                  <Require />
                </Typography>
                <Input
                  error={!(!errors.name || !touched.name)}
                  placeholder='Enter name'
                  fullWidth
                  onChange={handleChange}
                  name='name'
                  value={values.name}
                  InputProps={{
                    endAdornment: touched.name && errors.name && icon,
                  }}
                />
                {touched?.name && errors?.name && (
                  <Typography className='validation-text'>{errors.name}</Typography>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography className='field-title'>
                  ID
                  <Require />
                </Typography>
                <Input
                  error={!(!errors.uniqId || !touched.uniqId)}
                  placeholder='Enter ID'
                  fullWidth
                  onChange={handleChange}
                  name='uniqId'
                  value={values.uniqId}
                  InputProps={{
                    endAdornment: touched.uniqId && errors.uniqId && icon,
                  }}
                />
                {touched.uniqId && errors.uniqId && (
                  <Typography className='validation-text'>{errors.uniqId}</Typography>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography className='field-title'>
                  Factory Name
                  <Require />
                </Typography>
                <Input
                  error={!(!errors.factoryName || !touched.factoryName)}
                  placeholder='Enter Factory Name'
                  fullWidth
                  onChange={handleChange}
                  name='factoryName'
                  value={values.factoryName}
                  InputProps={{
                    endAdornment: touched.factoryName && errors.factoryName && icon,
                  }}
                />
                {touched.factoryName && errors.factoryName && (
                  <Typography className='validation-text'>{errors.factoryName}</Typography>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography className='field-title'>
                  Number of Worker
                  <Require />
                </Typography>
                <Input
                  error={!(!errors.numberOfWorker || !touched.numberOfWorker)}
                  placeholder='Enter Number of Worker'
                  fullWidth
                  onChange={handleChange}
                  name='numberOfWorker'
                  value={values.numberOfWorker}
                  InputProps={{
                    endAdornment: touched.numberOfWorker && errors.numberOfWorker && icon,
                  }}
                />
                {touched.numberOfWorker && errors.numberOfWorker && (
                  <Typography className='validation-text'>{errors.numberOfWorker}</Typography>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={2}>
              <Grid item xs={3}>
                <Typography className='field-title'>
                  Type of Account
                  <Require />
                </Typography>
                {/* <Input
                  error={!(!errors.typeOfAccount || !touched.typeOfAccount)}
                  placeholder='Enter type of account'
                  fullWidth
                  onChange={handleChange}
                  name='typeOfAccount'
                  value={values.typeOfAccount}
                  InputProps={{
                    endAdornment: touched.typeOfAccount && errors.typeOfAccount && icon,
                  }}
                />
                {touched.typeOfAccount && errors.typeOfAccount && (
                  <Typography className='validation-text'>{errors.typeOfAccount}</Typography>
                )} */}
                <FormControl fullWidth>
                  <Selects
                    error={!(!errors.typeOfAccount || !touched.typeOfAccount)}
                    onChange={(e) => {
                      if (e.target.value === 'Advanced') {
                        setFieldValue(`rights[${0}].access`, true);
                        setFieldValue(`rights[${1}].access`, true);
                        setFieldValue(`rights[${2}].access`, true);
                        setFieldValue(`rights[${3}].access`, true);
                        setFieldValue(`rights[${4}].access`, true);
                        setFieldValue(`rights[${5}].access`, true);
                        setFieldValue(`rights[${6}].access`, true);
                        setFieldValue(`rights[${7}].access`, true);
                        setFieldValue(`rights[${8}].access`, true);
                      } else if (e.target.value === 'Basic') {
                        setFieldValue(`rights[${0}].access`, true);
                        setFieldValue(`rights[${1}].access`, true);
                        setFieldValue(`rights[${2}].access`, true);
                        setFieldValue(`rights[${3}].access`, true);
                        setFieldValue(`rights[${4}].access`, true);
                        setFieldValue(`rights[${5}].access`, true);
                        setFieldValue(`rights[${6}].access`, false);
                        setFieldValue(`rights[${7}].access`, false);
                        setFieldValue(`rights[${8}].access`, false);
                      } else {
                        setFieldValue(`rights[${0}].access`, false);
                        setFieldValue(`rights[${1}].access`, false);
                        setFieldValue(`rights[${2}].access`, false);
                        setFieldValue(`rights[${3}].access`, false);
                        setFieldValue(`rights[${4}].access`, false);
                        setFieldValue(`rights[${5}].access`, false);
                        setFieldValue(`rights[${6}].access`, false);
                        setFieldValue(`rights[${7}].access`, false);
                        setFieldValue(`rights[${8}].access`, false);
                      }
                      handleChange(e);
                    }}
                    name='typeOfAccount'
                    value={values.typeOfAccount}
                    variant='outlined'
                    displayEmpty
                  >
                    <MenuItem value='' key={100000} disabled>
                      <span className='menu-item-span'>Select type of account</span>
                    </MenuItem>
                    <MenuItem value={'Free trial'}>Free trial</MenuItem>
                    <MenuItem value={'Basic'}>Basic</MenuItem>
                    <MenuItem value={'Advanced'}>Advanced</MenuItem>
                  </Selects>

                  {touched.typeOfAccount && errors.typeOfAccount && (
                    <Typography className='validation-text'>{errors.typeOfAccount}</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <Typography className='field-title'>
                  E-mail ID
                  <Require />
                </Typography>
                <Input
                  error={!(!errors.email || !touched.email)}
                  placeholder='Enter Email-ID'
                  fullWidth
                  onChange={handleChange}
                  name='email'
                  value={values.email}
                  InputProps={{
                    endAdornment: touched.email && errors.email && icon,
                  }}
                />
                {touched.email && errors.email && (
                  <Typography className='validation-text'>{errors.email}</Typography>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography className='field-title'>
                  Phone No.
                  <Require />
                </Typography>
                <Input
                  error={!(!errors.mobile || !touched.mobile)}
                  placeholder='Enter Mobile no.'
                  fullWidth
                  onChange={handleChange}
                  name='mobile'
                  value={values.mobile}
                  InputProps={{
                    endAdornment: touched.mobile && errors.mobile && icon,
                  }}
                />
                {touched.mobile && errors.mobile && (
                  <Typography className='validation-text'>{errors.mobile}</Typography>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography className='field-title'>
                  Number of Webend User
                  <Require />
                </Typography>
                <Input
                  error={!(!errors.numberOfWebendUser || !touched.numberOfWebendUser)}
                  placeholder='Enter Number of Webend user'
                  fullWidth
                  onChange={handleChange}
                  name='numberOfWebendUser'
                  value={values.numberOfWebendUser}
                  InputProps={{
                    endAdornment: touched.numberOfWebendUser && errors.numberOfWebendUser && icon,
                  }}
                />
                {touched.numberOfWebendUser && errors.numberOfWebendUser && (
                  <Typography className='validation-text'>{errors.numberOfWebendUser}</Typography>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={2}>
              <Grid item xs={2}>
                <Typography className='field-title'>
                  Select date
                  <Require />
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disablePast
                    // hideTabs
                    className='date-picker'
                    // openTo='year'
                    views={['year', 'day', 'month']}
                    closeOnSelect={false}
                    value={values.startDate || dayjs(new Date())}
                    // inputFormat=''
                    onChange={(newValue: any) => {
                      const newDate = moment(newValue?.toDate()).format('YYYY-MM-DD');
                      setFieldValue('startDate', newDate);
                    }}
                    components={{
                      ActionBar: ActionList,
                      // OpenPickerIcon: ClockIcon,
                    }}
                    componentsProps={{
                      actionBar: {
                        actions: ['today', 'cancel', 'clear'],
                      },
                    }}
                    renderInput={(params) => {
                      if (values?.startDate) {
                        return <TextField {...params} placeholder='Select time' />;
                      } else {
                        delete params['inputProps'];
                        return (
                          <TextField
                            {...params}
                            disabled={true}
                            placeholder='Select time'
                            error={false}
                          />
                        );
                      }
                    }}
                  />
                </LocalizationProvider>
                {errors.startDate && touched.startDate && (
                  <Typography className='validation-text'>{errors.startDate}</Typography>
                )}
              </Grid>
            </Grid>
            <Divider />
            <Typography className='title-typography'> Subscription configuration</Typography>
            <Box height={'auto'}>
              <TableContainer component={Box}>
                <Table sx={{ minWidth: 700 }} aria-label='customized table'>
                  <TableHead>
                    <TableRow>
                      <TableDataCell>Module</TableDataCell>
                      <TableDataCell align='center'>Dashboard</TableDataCell>
                      <TableDataCell align='center'>Factory Management</TableDataCell>
                      <TableDataCell align='center'>Resources</TableDataCell>
                      <TableDataCell align='center'>Catalog</TableDataCell>
                      <TableDataCell align='center'>Processes</TableDataCell>
                      <TableDataCell align='center'>Work Orders</TableDataCell>
                      <TableDataCell align='center'>
                        Event based Triggers & notification
                      </TableDataCell>
                      <TableDataCell align='center'>Issue Reporting</TableDataCell>
                      <TableDataCell align='center'>Tickets</TableDataCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow style={{ padding: '0px' }}>
                      <TableDataCell
                        component='th'
                        scope='row'
                        sx={{ width: '150px', padding: '0px' }}
                      >
                        Access
                      </TableDataCell>
                      {values.rights.map((row, ind) => {
                        return (
                          <TableDataCell align='center' key={ind}>
                            <Checkbox
                              name={`rights[${ind}].access`}
                              checked={!!values?.rights[ind]?.access}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              icon={<CheckboxIcon />}
                              checkedIcon={<CheckboxFilledIcon />}
                            />
                          </TableDataCell>
                        );
                      })}
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box mt={'95px'}>
              <Divider />
              <Box className='button-box'>
                <CustomButton
                  className={!id && !(isValid && dirty) ? 'disable-btn' : 'yellow-bg'}
                  variant='contained'
                  type='submit'
                >
                  Continue
                </CustomButton>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {/* <Loading loading={id && !isSuccess ? true : false} background='white' loaderColor='#265AA8' /> */}
      {isErrorModalOpen && (
        <ErrorPopup
          isOpen={isErrorModalOpen}
          handleClose={handleErrorModalClose}
          errorDetails={errorMessage}
        />
      )}
    </BoxWrapper>
  );
};

export default AddNewAdmin;
