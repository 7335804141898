import { Box } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useVerifyToken } from '../hooks/authHooks';
import { user } from '../queryKeys/userManagement';
import { cookie } from '../Utils/Cookies/cookie';
import Header from './Header';

const ComponentWrapper = () => {
  const queryClient = useQueryClient();
  const token = cookie.get('token');
  const userRole: any = queryClient.getQueryData([user.LOGGED_IN_USER]);
  let isAuthenticated = false;
  if (token) {
    const { data: UserDetails, isLoading, status } = useVerifyToken();
  }
  if (token) {
    isAuthenticated = true;
  }
  return (
    <React.Fragment>
      <Box display='flex'>
        <Header isAuthenticated={isAuthenticated} />
      </Box>
    </React.Fragment>
  );
};
export default ComponentWrapper;
