import React, { useState } from 'react';
import { Box, Breadcrumbs, Chip, Divider, Link, Stack, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { GoPrimitiveDot } from 'react-icons/go';
// import DeleteWarningModal from '../../Modals/DeleteWarningModal';
// import CustomModalButton from './CustomModalButton';
import { useLocation } from 'react-router-dom';
import DeleteWarningModal from '../modal/DeleteWarningModal';
// import RestoreWarningModal from '../../Modals/RestoreWarningModal';
interface breadCrumbData {
  title: string;
  link: string;
}
interface breadCrumbModel {
  pageTitle: string;
  backToLink: string;
  breadCrumbData?: breadCrumbData[];
  isDetailPage?: boolean;
  detailPageData?: breadCrumbData[];
  hideDivider?: boolean;
  isStatusShow?: boolean;
  isOnline?: boolean;
  handleDelete?: () => void;
  hideDelete?: boolean;
  isDeleted?: boolean;
  isRestore?: boolean;
  handleRestore?: void;
}
const BreadcrumbNavigation: React.FC<breadCrumbModel> = (props) => {
  const {
    breadCrumbData,
    pageTitle,
    backToLink,
    isDetailPage = false,
    detailPageData = [],
    hideDivider = false,
    hideDelete = false,
    handleDelete,
    // handleRestore,
  } = props;
  let editLink: string | undefined = '';
  // let deleteLink: string | undefined = '';
  let variantLink: string | undefined = '';
  if (isDetailPage) {
    if (detailPageData?.length) {
      editLink = detailPageData?.find((data) => data.title === 'edit')?.link;
      // deleteLink = detailPageData?.find((data) => data.title === 'delete')?.link;
      variantLink = detailPageData?.find((data) => data.title === '+ Create Variant')?.link;
    }
  }
  const location = useLocation();
  const isDeletedItemsPage = location.pathname.startsWith('/deleted-items');
  const BreadCrumbWrapper = styled(Box)(() => ({
    '.title-text': {
      fontSize: '34px',
      fontWeight: '700',
      color: '#265AA8',
      paddingLeft: '33px',
      textTransform: 'capitalize',
    },
    '.back-arrow': {
      cursor: 'pointer',
    },
    '.iconText': {
      fontSize: '14px',
      color: '#265AA8',
      fontWeight: 500,
    },
    '.icon-text-wrapper': {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
    },
    '.mr-23px': {
      marginRight: '23px',
    },
    '.icon': {
      color: '#265AA8',
      paddingRight: '8px',
      fontSize: '15px !important',
    },
    '.online-status': {
      paddingLeft: '12px',
    },
    '.ml-26': {
      marginLeft: '26px',
    },
  }));
  const navigate = useNavigate();
  const breadCrumbs = breadCrumbData?.map((breadCrumb, index) => (
    <Link
      underline='hover'
      key={index}
      className='breadcrumb-link'
      color='#265AA8'
      onClick={() => {
        navigate(breadCrumb.link);
      }}
    >
      {breadCrumb.title}
    </Link>
  ));
  // Delete Modal
  const [deleteRecord, setDeleteRecord] = useState(false);
  const handleDeleteOpen = () => {
    setDeleteRecord(true);
  };
  const handleDeleteClose = () => {
    setDeleteRecord(false);
  };
  // const [restoreRecord, setRestoreRecord] = useState(false);
  // const handleRestoreOpen = () => {
  //   setRestoreRecord(true);
  // };
  // const handleRestoreClose = () => {
  //   setRestoreRecord(false);
  // };
  const { id } = useParams();
  return (
    <BreadCrumbWrapper>
      {breadCrumbs && (
        <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
          {breadCrumbs}
        </Breadcrumbs>
      )}
      <Box display='flex' alignItems='center' justifyContent={'space-between'}>
        <Box display={'flex'} alignItems='center'>
          <ArrowBackIcon
            className='back-arrow'
            onClick={() => {
              navigate(backToLink);
            }}
          />
          <Typography className='title-text'>{pageTitle}</Typography>
          {props.isStatusShow && (
            <Stack direction='row' spacing={1} className='online-status'>
              <Chip
                avatar={<GoPrimitiveDot color={`${props.isOnline ? 'green' : 'red'}`} />}
                label={`${props.isOnline ? 'Online' : 'Offline'}`}
                variant='outlined'
              />
            </Stack>
          )}
        </Box>
        {(isDetailPage || isDeletedItemsPage) && (
          <Box display={'flex'}>
            {!props.isDeleted && detailPageData.length > 2 && (
              <Box
                className='icon-text-wrapper mr-23px'
                onClick={() => {
                  navigate(variantLink || '');
                }}
              >
                <AddIcon className={'icon'} />
                <Typography className={'iconText'}>Create Variant</Typography>
              </Box>
            )}
            {!props.isDeleted && (
              <Box
                className='icon-text-wrapper mr-23px'
                onClick={() => {
                  navigate(editLink || '');
                }}
              >
                <EditOutlinedIcon className={'icon'} />
                <Typography className={'iconText'}>Edit</Typography>
              </Box>
            )}
            {!hideDelete && !props.isDeleted && (
              <Box
                className='icon-text-wrapper'
                onClick={() => {
                  handleDeleteOpen();
                }}
              >
                <DeleteOutlinedIcon className={'icon'} />
                <Typography className={'iconText'}>Delete</Typography>
              </Box>
            )}
            {/* {props.isRestore && (
              <CustomModalButton variant='outlined' onClick={() => handleRestoreOpen()}>
                Restore
              </CustomModalButton>
            )}
            {props.isDeleted && (
              <CustomModalButton className='yellow-bg ml-26' onClick={() => handleDeleteOpen()}>
                Delete
              </CustomModalButton>
            )} */}
          </Box>
        )}
      </Box>
      {deleteRecord && (
        <DeleteWarningModal
          open={deleteRecord}
          handleOpen={handleDeleteOpen}
          handleClose={handleDeleteClose}
          handleDelete={handleDelete}
          id={id}
        />
      )}
      {/* {restoreRecord && (
        <RestoreWarningModal
          open={restoreRecord}
          handleOpen={handleRestoreOpen}
          handleClose={handleRestoreClose}
          handleDelete={handleRestore}
          id={id}
        />
      )} */}
      {!hideDivider && <Divider />}
    </BreadCrumbWrapper>
  );
};
export default BreadcrumbNavigation;
