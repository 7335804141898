import { Box, styled, Typography } from '@mui/material';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import EditIcon from '../assets/svg/EditIcon';
import DeleteIcon from '../assets/svg/DeleteIcon';
// import moment from 'moment';
import CustomTable from '../commomComponent/Table';
import { useDeleteAdmin, useGetAdmins } from '../hooks/adminHooks';
import DeleteWarningModal from '../modal/DeleteWarningModal';
const SupperAdmin: React.FC = () => {
  //Delete Modal
  const [deleteRecord, setDeleteRecord] = useState(false);
  const [currentId, setCurrentId] = useState('');
  console.log('CURRENTID---', currentId);

  const handleDeleteOpen = () => {
    setDeleteRecord(true);
  };
  const handleDeleteClose = () => {
    setDeleteRecord(false);
  };

  //get admin list
  const [page, setPage] = useState(1);
  const { data: admins } = useGetAdmins(page);

  //delete admin
  const { mutate: deleteAdmin } = useDeleteAdmin();

  const navigate = useNavigate();

  const BtnAdminLink = {
    title: 'Add Admin +',
    linkData: [
      {
        menuItem: 'Add Admin',
        to: '/add-admin',
      },
    ],
    isBtnShow: true,
  };

  const adminCols = [
    {
      field: 'srNo',
      headerName: 'Sr. No',
      flex: 0.4,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'uid',
      headerName: 'ID',
      flex: 1,
    },
    {
      field: 'factory_name',
      headerName: 'Factory Name',
      flex: 1,
    },
    {
      field: 'storage_used',
      headerName: 'Storage Used',
      flex: 1,
    },
    {
      field: 'product',
      headerName: 'Products',
      flex: 1,
    },
    {
      field: 'machine',
      headerName: 'Machine',
      flex: 1,
    },
    {
      field: 'type_of_account',
      headerName: 'Type of Amount',
      flex: 1,
    },
    {
      field: 'active_since',
      headerName: 'Active Since',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      // width: 200,
      disableClickEventBubbling: true,

      renderCell: (params) => {
        return (
          <Box className='table-icons'>
            {/* {(isAllPermission || processRights?.edit) && ( */}
            <Box
              pr={'25px'}
              onClick={() => {
                navigate(`/edit-admin/${params.row.id}`);
              }}
            >
              <EditIcon />
            </Box>
            {/* )} */}
            {/* {(isAllPermission || processRights?.delete) && ( */}
            <Box
              onClick={() => {
                setCurrentId(params.row.id);
                handleDeleteOpen();
              }}
            >
              <DeleteIcon />
            </Box>
            {/* )} */}
          </Box>
        );
      },
    },
  ];

  //   const processRows = getProcesses?.map((data, ind) => {
  //     return {
  //       id: data.id,
  //       srNo: ind + 1,
  //       name: data.name,
  //       number: data.number,
  //       machine: data.machine?.name,
  //       process_time: moment(data?.processTime).format('hh:mm:ss'),
  //       action: '',
  //     };
  //   });

  // const adminRows = [1, 2, 3, 4, 5, 6, 7, 8]?.map((data, ind) => {
  //   return {
  //     srNo: ind + 1,
  //     id: ind,
  //     name: 'Name',
  //     uid: 'QM321',
  //     factory_name: 'Factory Name',
  //     storage_used: '45gb',
  //     product: '23',
  //     machine: '40',
  //     type_of_account: 'Basic',
  //     active_since: '12-03-21',
  //     action: '',
  //   };
  // });
  const adminRows = admins?.results?.map((data, ind) => {
    const startIndex = (page - 1) * 10;
    return {
      srNo: startIndex + ind + 1,
      id: data?.id,
      name: data?.name,
      uid: data?.uniqId,
      factory_name: data?.factoryName,
      storage_used: '45gb',
      product: '23',
      machine: '40',
      type_of_account: data?.typeOfAccount,
      active_since: data?.startDate,
      action: '',
    };
  });

  const tableData = {
    columns: adminCols,
    rows: adminRows,
    detailsRedirection: '/admin-details',
  };
  const BoxWrapper = styled(Box)(() => ({
    marginTop: '45px',
    color: '#000',
    '.page-head': {
      fontSize: '34px !important',
      color: '#265AA8',
      lineHeight: 'none',
      fontWeight: '700 !important',
      '.css-1wczmbf-MuiTypography-root': {
        lineHeight: '0',
      },
    },
  }));

  return (
    <React.Fragment>
      <BoxWrapper>
        <Typography className='page-head' mb={'0px'}>
          Inovec Customers
        </Typography>
        {/* {(isAllPermission || !_.isEmpty(processRights)) && ( */}
        <CustomTable
          data={BtnAdminLink}
          isOptionShow={true}
          tableData={tableData}
          paginationData={admins}
          page={page}
          setPage={setPage}
          // isLoading={isProcessLoading || isProcessFetching}
        />
        {/* )} */}

        {deleteRecord && currentId && (
          <DeleteWarningModal
            open={deleteRecord}
            handleOpen={handleDeleteOpen}
            handleClose={handleDeleteClose}
            handleDelete={deleteAdmin}
            id={currentId}
          />
        )}
      </BoxWrapper>
    </React.Fragment>
  );
};
export default SupperAdmin;
