import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import styled from '@emotion/styled';
import './../../src/style/modal.css';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: 'background.paper',
  //border: '2px solid #000',
  borderRadius: '32px',
  boxShadow: 24,
  p: 4,
};

const BoxWrapper = styled(Box)(() => ({
  '.button-box': {
    width: '150px',
    padding: '5px',
    fontSize: '16px !important',
    fontWeight: 500,
    borderRadius: '10px !important',
  },
}));

const DeleteWarningModal = (
  { open, handleClose, handleDelete, id, isDeleteOrRestoreAll = false }: any // params = false,
) => {
  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <BoxWrapper>
            <Box textAlign={'center'}>
              <Box m={2}>
                <Typography>Are yor sure you want to delete this record ?</Typography>
              </Box>

              <Box m={2} display={'flex'} justifyContent={'space-between'}>
                <Button variant='outlined' className='button-box' onClick={() => handleClose()}>
                  Cancel
                </Button>
                <Button
                  variant='outlined'
                  className='button-box yellow-bg'
                  onClick={() => {
                    if (isDeleteOrRestoreAll) {
                      handleDelete();
                      handleClose();
                    } else {
                      handleDelete(id);
                      handleClose();
                    }
                  }}
                >
                  Delete
                </Button>
              </Box>
            </Box>
          </BoxWrapper>
        </Box>
      </Modal>
    </div>
  );
};
export default DeleteWarningModal;
