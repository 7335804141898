import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { uploadApi } from '../api/uploadFile';

export const useUploadFile = () => {
  return useMutation(uploadApi.addFile, {
    onSuccess: (res) => {
      const data = res.data;
      toast.success(data?.message);
    },
    onError: (err: any) => {
      const errData = err?.data;
      toast.error(errData.message);
    },
  });
};
