import * as React from 'react';
import Box from '@mui/material/Box';
import { Divider, Grid } from '@mui/material';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import * as _ from 'lodash';
import { useParams } from 'react-router-dom';
import CheckboxIcon from '../assets/svg/CheckboxIcon';
import CheckboxFilledIcon from '../assets/svg/CheckboxFilledIcon';
import BreadcrumbNavigation from '../commomComponent/BreadCrumbNavigation';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useDeleteAdmin, useGetAdmin } from '../hooks/adminHooks';
import moment from 'moment';

const TextFiled = styled(Typography)(() => ({
  color: 'gray',
  paddingTop: '10px',
}));

const HeadBoxWrapper = styled(Box)(() => ({
  marginTop: '14px',
  paddingBottom: '40px',
  '.head-text': {
    fontSize: '32px',
    padding: '30px 0px 18px 0px',
    borderTop: '1px solid #D2D1D1',
  },
  '.title-texts': {
    fontSize: '14px',
    color: '#265AA8',
  },
  '.title-last': {
    fontSize: '34px',
    fontWeight: '700',
    color: '#265AA8',
  },
  '.title-box': {
    display: 'flex',
    fontSize: '50px',
    paddingBottom: '10px',
  },
  '.title-head-box': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '20px',
  },
  '.title-sub-box': {
    display: 'flex',
    fontSize: '34px',
    alignItems: 'center',
  },
  '.add-icon': {
    paddingRight: '20px',
  },
  '.right-icon': {
    fontSize: '20px',
    color: '#265AA8',
  },
  '.validation-text': {
    color: 'red',
    fontSize: '12px',
  },
  '.button-box': {
    display: 'flex',
    justifyContent: 'end',
  },
  '.field-title': {
    fontSize: 14,
    fontWeight: 500,
    padding: '2px 0px 8px 0px',
    color: '#000',
  },
  '.sub-head-text': {
    fontSize: '20px',
    fontWeight: '500',
  },
  '.icon': {
    fontSize: '20px',
    color: '#265AA8',
    paddingRight: '5px',
    cursor: 'pointer',
  },
  '.iconText': {
    fontSize: '14px',
    color: '#265AA8',
    cursor: 'pointer',
  },
  '.detailsHead': {
    fontSize: '20px',
    fontWeight: '700',
    margin: '12px 0px',
  },
}));

const TableDataCell = styled(TableCell)(() => ({
  // padding: '16px 0px 16px 16px',
  padding: '4px',
}));

const StyledTableRow = styled(TableRow)(() => ({
  '.MuiTableCell-root': {
    borderBottom: 'none !important',
  },
  '&:nth-of-type(odd)': {
    backgroundColor: '#F4F9FF',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const AdminDetails = () => {
  const { id } = useParams();
  //delete admin
  const { mutate: deleteAdmin } = useDeleteAdmin();

  const handleDeleteClick = () => {
    deleteAdmin(id);
  };
  console.log('dele---', handleDeleteClick);

  //get single Admin
  const { data: getAdmin } = useGetAdmin(id);
  const admin = getAdmin?.user;

  const subscription = admin?.subscriptions;
  const modulesToAccess = [
    'dashboard',
    'factoryManagement',
    'resources',
    'catalogue',
    'processes',
    'workorders',
    'eventBasedTriggersAndNotifications',
    'issuesReporting',
    'ticket',
  ];
  const subscriptionRights = modulesToAccess?.map((access) => {
    const currentAccess = subscription?.find((data) => data?.userModule === access);
    return currentAccess;
  });
  //   console.log('subscriptionRights-->', subscriptionRights);
  const detailPageData = [
    {
      title: 'edit',
      link: `/edit-admin/${id}`,
    },
    {
      title: 'delete',
      link: '',
    },
  ];
  return (
    <HeadBoxWrapper>
      <BreadcrumbNavigation
        pageTitle={_.get(admin, 'name', '')}
        backToLink='/'
        isDetailPage={true}
        detailPageData={detailPageData}
        handleDelete={handleDeleteClick}
      />
      <Box className='sub-box'>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            {/* <Typography id='modal-modal-title' className='title-typography'>
              Basic details
            </Typography> */}
            <TextFiled>
              Name: <span className='span-color'>{_.get(admin, 'name', '')}</span>
            </TextFiled>
            <TextFiled>
              ID: <span className='span-color'>{_.get(admin, 'uniqId', '')}</span>
            </TextFiled>
            <TextFiled>
              Factory Name: <span className='span-color'>{_.get(admin, 'factoryName', '')}</span>
            </TextFiled>
            <TextFiled>
              Number Of Webend User:{' '}
              <span className='span-color'>{_.get(admin, 'numberOfWebendUser', '')}</span>
            </TextFiled>
          </Grid>
          <Grid item xs={3}>
            <TextFiled>
              Type of Account:{' '}
              <span className='span-color'>{_.get(admin, 'typeOfAccount', '')}</span>
            </TextFiled>
            <TextFiled>
              E-mail ID: <span className='span-color'>{_.get(admin, 'email', '')}</span>
            </TextFiled>
            <TextFiled>
              Phone Number: <span className='span-color'>{_.get(admin, 'mobile', '')}</span>
            </TextFiled>
            <TextFiled>
              Number Of Worker:{' '}
              <span className='span-color'>{_.get(admin, 'numberOfWorker', '')}</span>
            </TextFiled>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box display={'flex'}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextFiled>
              Storage Used: <span className='span-color'>{_.get(admin, '', '')}</span>
            </TextFiled>
            <TextFiled>
              Product: <span className='span-color'>{_.get(admin, '', '')}</span>
            </TextFiled>
            <TextFiled>
              Machine: <span className='span-color'>{_.get(admin, '', '')}</span>
            </TextFiled>
            <TextFiled>
              start Data:{' '}
              <span className='span-color'>
                {/* {moment(_.get('singleProcess', 'processTime', '')).format('hh:mm:ss')} */}
                {moment(_.get(admin, 'startDate', '')).format('YYYY-MM-DD')}
              </span>
            </TextFiled>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Typography className='title-typography'> Subscription configuration</Typography>
      <Box height={'auto'}>
        <TableContainer component={Box}>
          <Table aria-label='customized table'>
            <TableHead>
              <TableRow>
                <TableDataCell>Module</TableDataCell>
                <TableDataCell align='center'>Dashboard</TableDataCell>
                <TableDataCell align='center'>Factory Management</TableDataCell>
                <TableDataCell align='center'>Resources</TableDataCell>
                <TableDataCell align='center'>Catalog</TableDataCell>
                <TableDataCell align='center'>Processes</TableDataCell>
                <TableDataCell align='center'>Work Orders</TableDataCell>
                <TableDataCell align='center'>Event based Triggers & notification</TableDataCell>
                <TableDataCell align='center'>Issue Reporting</TableDataCell>
                <TableDataCell align='center'>Tickets</TableDataCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow style={{ padding: '0px' }}>
                <TableDataCell component='th' scope='row' sx={{ width: 'auto', padding: '0px' }}>
                  Access
                </TableDataCell>
                {subscriptionRights?.map((row, ind) => {
                  console.log('row===>', row);
                  return (
                    <TableDataCell align='center' key={ind}>
                      {row?.access ? <CheckboxFilledIcon /> : <CheckboxIcon />}
                      {}
                    </TableDataCell>
                  );
                })}
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {/* <Loading
        loading={id && (isDeleted ? !isSuccessDeleted : !isSuccess) ? true : false}
        background='white'
        loaderColor='#265AA8'
      /> */}
    </HeadBoxWrapper>
  );
};
export default AdminDetails;
