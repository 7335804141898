import React from 'react';
import { AppBar, Box, styled } from '@mui/material';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import SearchComponent from './SearchComponent';

// import NotificationModal from '../Modals/NotificationModal';
// import ProfileModal from '../Modals/ProfileModal';
import UserInfo from './UserInfo';
import InovacLogo from '../assets/svg/InovacLogo';
import { useQueryClient } from '@tanstack/react-query';
import { user } from '../queryKeys/userManagement';

// const Header = ({ Component }: ReactComponent) => {
const Header = ({ isAuthenticated }: any) => {
  // let isAuthenticated = false;
  const location = useLocation();
  // const token = cookie.get('token');
  const queryClient = useQueryClient();
  const adminData: any = queryClient.getQueryData([user.LOGGED_IN_USER]);
  console.log('adminData---', adminData);

  // if (token) {
  //   const { data: UserDetails, isLoading, status } = useVerifyToken();
  // }
  // // if (isLoading === false && UserDetails?.data.user) {
  // if (token) {
  //   isAuthenticated = true;
  // }
  const isHideHeader =
    location.pathname.startsWith('/work-order/add-procedure/') ||
    location.pathname.startsWith('/work-order/edit-procedure/') ||
    location.pathname.startsWith('/work-order/view-procedure/');
  const HeaderWrapper = styled(Box)(() => ({
    // marginLeft: '13%',
    width: '100%',
    position: 'sticky',
    // padding: '0px 39px 0px 40px',
    // background: '#265AA8',
    '.img-box-padding': {
      padding: '30px 0px 0px 34px',
    },
    '.search-box-padding': {
      padding: '43px 0px 0px 40px',
    },
    '.profile-box-padding': {
      padding: '43px 0px 0px 40px',
    },
    '.app-bar': {
      // paddingTop: '43px',
      // marginLeft: '200px',
      padding: '0px 39px 0px 0px',
      background: '#265AA8',
      zIndex: -10,
      // zIndex: 1,
    },
    '.main-box': {
      // padding: isHideHeader ? '0px' : '0px 39px 2px 40px',
      padding: '0px 39px 2px 40px',
    },
    '.search-box': {
      boxShadow: '0px 2px 10px rgba(40, 40, 40, 0.1)',
      height: '50px',
      color: 'gray',
      width: '400px',
      '.css-i4bv87-MuiSvgIcon-root': {},
      borderRadius: '10px',
      paddingLeft: '10px',
    },
    '.search-icon': {
      color: '#265AA8',
    },
    '.notification-icon': {
      cursor: 'pointer',
      height: '55px',
      width: '55px',
      background: '#fff',
      color: '#265AA8',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '30px',
      margin: '0px 11px 0px 0px',
      boxShadow: '0px 2px 10px rgba(40, 40, 40, 0.1)',
    },
    '.avatar-box': {
      height: '40px',
      width: '230px',
      background: '#fff',
      color: 'blue',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '10px',
      boxShadow: '0px 2px 10px rgba(40, 40, 40, 0.1)',
      padding: '8px 0px 7px 13px',
      cursor: 'pointer',
    },
  }));

  // const LightTooltip = styled(({ className, ...props }) => (
  //   <Tooltip {...props} classes={{ popper: className }} />
  // ))(({ theme }) => ({
  //   [`& .${tooltipClasses.tooltip}`]: {
  //     // backgroundColor: theme?.palette?.common?.#22232E,
  //     backgroundColor: '#171820',
  //     color: '#ffffff',
  //     padding: '10px',
  //     boxShadow: theme,
  //     fontSize: 13,
  //     fontWeight: 'bold',
  //   },
  // }));
  return (
    <React.Fragment>
      <HeaderWrapper>
        {!isHideHeader && (
          <AppBar position='sticky' color='transparent' elevation={0} className='app-bar'>
            <Box display={'flex'} justifyContent='space-between' pb={'27px'}>
              <Box className='head-image' display={'flex'}>
                {/* <img src={logo} alt='logo' height={123} width={336} /> */}
                <Box className='img-box-padding'>
                  <InovacLogo />
                </Box>
                {/* <Box className='search-box-padding'>
                  <SearchComponent />
                </Box> */}
              </Box>
              {/* <Box>
              <InputBase
                className='search-box'
                placeholder='Search here ...'
                startAdornment={
                  <InputAdornment position='start'>
                    <SearchIcon className='search-icon' />
                  </InputAdornment>
                }
              />
            </Box> */}
              <Box className='profile-box-padding'>
                <UserInfo adminData={adminData} />
              </Box>
            </Box>
          </AppBar>
        )}
        <Box className='main-box'>{isAuthenticated ? <Outlet /> : <Navigate to={'/login'} />}</Box>
        {/* <Box className='main-box'>{isAuthenticated ? <Outlet /> : <Navigate to={'/'} />}</Box> */}

        {/* <Box className='main-box'>
          <Outlet />
        </Box> */}
      </HeaderWrapper>
    </React.Fragment>
  );
};
export default Header;
