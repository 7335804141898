import { get, post, put, patch } from '../Utils/index';

export const login = async (data) => {
  return post(`/admin/auth/login`, data);
};

// export const forgotPassword = (data) => {
//   return post(`/admin/auth/forgot-password`, data);
// };

export const changePassword = (data) => {
  return put(`/admin/profile/change-password`, data);
};
export const updateProfile = async (data) => {
  // const id = data?.queryKey[1];
  const id = data?.id;
  delete data['id'];
  return patch(`/admin/profile/update-info`, data);
};
export const verifyToken = () => {
  return get(`/admin/profile/me`);
};

// export const resetPassword = () => {
//   return post(`/admin/auth/reset-password`);
// };

export const authApi = {
  login,
  verifyToken,
  changePassword,
  updateProfile,
};
