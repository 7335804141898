const CheckboxFilledIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_1526_55660)'>
        <rect x='0.5' y='0.5' width='23' height='23' rx='7.5' stroke='#F19E38' />
        <path
          d='M8.125 13.125L10.625 15.625L16.875 9.375'
          stroke='#F19E38'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1526_55660'>
          <rect width='24' height='24' rx='8' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckboxFilledIcon;
