import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import './../../src/style/modal.css';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
// import { user } from '../queryKeys/userManagement';
// import { useUpdateProfile } from '../hooks/authHooks';
import moment from 'moment';
import profileLogo from '../assets/images/profile.png';
import ProfileAvatar from '../commomComponent/ProfileAvtar';
import ProfileCard from '../commomComponent/ProfileCard';
import { user } from '../queryKeys/userManagement';
import { useUpdateProfile } from '../hooks/authHooks';
import ErrorPopup from '../commomComponent/ErrorPopup';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  //border: '2px solid #000',
  borderRadius: '32px',
  boxShadow: 24,
  p: 4,
};

const BoxWrapper = styled(Box)(() => ({
  '.button-box': {
    padding: '5px',
    fontSize: '16px !important',
    fontWeight: 500,
    borderRadius: '10px !important',
  },
}));

const ShowProfile = ({ open, handleOpen, handleClose }: any) => {
  const queryClient = useQueryClient();
  const userData: any = queryClient.getQueryData([user.LOGGED_IN_USER]);
  const adminProfile: any = userData?.data?.user;
  console.log('userprofile---', adminProfile);

  const [name, setName] = React.useState<any>(adminProfile?.name);
  const [email, setEmail] = React.useState<any>(adminProfile?.email);
  const [dob, setDob] = React.useState<any>(moment(adminProfile?.dob).format('YYYY-MM-DD'));
  console.log('name--', name);
  console.log('email--', email);
  console.log('dob--', dob);
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false);
  const handleErrorModalClose = () => setIsErrorModalOpen(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const {
    mutate: updateProfile,
    isError: isUpdateProfileError,
    error: updateProfileError,
  } = useUpdateProfile();
  React.useEffect(() => {
    if (isUpdateProfileError) {
      setErrorMessage(updateProfileError?.data?.message);
      setIsErrorModalOpen(true);
    }
  }, [isUpdateProfileError]);

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <BoxWrapper>
            <Box display={'flex'} justifyContent={'center'}>
              {/* <div>
                <label htmlFor='newFile'>
                  <Avatar
                    sx={{ width: 50, height: 50 }}
                    alt=''
                    src={profilePic || userProfile?.profilePic}
                  >
                    {userProfile?.displayName?.[0]?.toUpperCase() ||
                      userProfile?.name?.[0]?.toUpperCase()}
                  </Avatar>
                  <input onChange={(e) => handleFile(e)} type='file' id='newFile' hidden />
                </label>
              </div> */}
              <ProfileAvatar handleOpen={handleOpen} />
            </Box>
            <Box>
              <Box m={2}>
                <ProfileCard field={'name'} value={name} setData={setName} />
              </Box>
              <Box m={2}>
                <ProfileCard field={'Email'} value={email} setData={setEmail} />
              </Box>
              <Box m={2}>
                <ProfileCard field={'D.O.B'} value={dob} setData={setDob} />
              </Box>
            </Box>
            <Box display={'flex'} justifyContent={'center'}>
              <Button
                variant='contained'
                className='button-box yellow-bg'
                onClick={() => {
                  console.log('dob===>', dob);
                  const Profile = {
                    name: name,
                    email: email,
                    dob: moment(dob).format('YYYY-MM-DD'),
                  };
                  updateProfile({ ...Profile, id: adminProfile?.id });
                }}
              >
                update Profile
              </Button>
            </Box>
          </BoxWrapper>
        </Box>
      </Modal>
      {isErrorModalOpen && (
        <ErrorPopup
          isOpen={isErrorModalOpen}
          handleClose={handleErrorModalClose}
          errorDetails={errorMessage}
        />
      )}
    </div>
  );
};
export default ShowProfile;
