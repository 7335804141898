import styled from '@emotion/styled';
import { Button } from '@mui/material';

const CustomButton = styled(Button)(() => ({
  padding: '19px 40px 20px 40px !important',
  borderRadius: '10px !important',
  fontSize: '16px !important',
  fontWeight: '500 !important',
  textTransform: 'capitalize',
  height: '60px !important',
}));

export default CustomButton;
