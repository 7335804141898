import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { adminApi } from '../api/admin';
import { admin } from '../queryKeys/superAdmin';

export const useGetAdmin = (id: any, isEnable = true) => {
  return useQuery([admin.ADMIN, id], adminApi.getSingleAdmin, {
    select: (data) => data.data,
    enabled: isEnable,
    onSuccess: (res) => {
      const data = res?.data;
      //toast.success(data?.message);
    },
    onError: (err: any) => {
      const errData = err?.data;
      //toast.error(errData.message);
    },
    refetchOnWindowFocus: false,
  });
};

export const useGetAdmins = (page: number) => {
  return useQuery([admin.ADMINS, page], adminApi.getAdmins, {
    select: (data) => data?.data?.users,
    onSuccess: (res) => {
      const data = res?.data;
    },
    onError: (err: any) => {
      const errData = err?.data;
      //toast.error(errData.message);
    },
    refetchOnWindowFocus: false,
  });
};

export const useAddAdmin = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(adminApi.addAdmin, {
    onSuccess: (res) => {
      queryClient.invalidateQueries([admin.ADMIN]);
      const data = res.data;
      //toast.success(data?.message);
      navigate(`/admin-details/${data?.user.id}`);
    },
    onError: (err: any) => {
      const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};

export const useDeleteAdmin = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(adminApi.deleteAdmin, {
    onSuccess: (res, id) => {
      const data = res.data;
      queryClient.invalidateQueries([admin.ADMINS]);
      queryClient.removeQueries([admin.ADMIN, id]);
      navigate(`/`);
    },
    onError: (err: any) => {
      const errData = err.data;
    },
  });
};

export const useUpdateAdmin = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(adminApi.updateAdmin, {
    onSuccess: (res, id) => {
      const data = res.data;
      queryClient.invalidateQueries([admin.ADMINS]);
      queryClient.invalidateQueries([admin.ADMIN, id]);
      navigate(`/admin-details/${data?.user?.id}`);
    },
    onError: (err: any) => {
      // const errData = err.data;
    },
  });
};
