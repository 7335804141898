const EditIcon = (prop: any) => {
  return (
    <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.6935 4.86019L15.6398 7.80647M13.9435 3.61019C14.7571 2.7966 16.0762 2.7966 16.8898 3.61019C17.7034 4.42379 17.7034 5.74288 16.8898 6.55647L5.41667 18.0296H2.5V15.0537L13.9435 3.61019Z'
        stroke='#265AA8'
        strokeWidth='1.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default EditIcon;
